import html2canvas from 'html2canvas';

export default function exportImg(selector,name="代码截图"){
    html2canvas(document.querySelector(selector), { // 转换为图片
        useCORS: true // 解决资源跨域问题
    }).then(canvas => {
        let imgUrl = canvas.toDataURL('image/png');
        downloadIamge(imgUrl, name + ".png")
    })
}

function downloadIamge(imgsrc, name) {
    let image = new Image();
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        let a = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = imgsrc;
}

