import { useState } from 'react';
import CodeImg from './CodeImg';
import './App.css';

function App() {
  const [fontsize, setFontsize] = useState(1);
  const [name, setName] = useState("代码截图");
  const [watermark, setWatermark] = useState("码中人 http://www.mzh.ren/");

  const nameHandle = (e) => setName(e.target.value);
  const watermarkHandle = (e) => setWatermark(e.target.value);
  const clearCode = () => document.getElementById('editor-content').innerHTML = "";

  return (
    <div className="App">

      <h1>简单代码截图</h1>

      <div className="controls">
        <button onClick={() => { if (fontsize < 4) { setFontsize(fontsize + 1) } }}>字体放大</button>
        <button onClick={() => { if (fontsize > 1) { setFontsize(fontsize - 1) } }}>字体缩小</button>
        <button onClick={ clearCode }>清空</button>
        <button onClick={() => CodeImg('#editor', name)}>下载</button>
      </div>

      <div className="editor-wrapper" id="editor">
        <div className="editor-header">
          <input type="text" value={name} onChange={nameHandle} className="name" />
          <div class="editor-window--actions">
            <span class="editor-window--action exit"></span>
            <span class="editor-window--action minimize"></span>
            <span class="editor-window--action maximize"></span>
          </div>
        </div>

        <div contentEditable="true" className={"x" + fontsize} id="editor-content">
        </div>

        <div className="editor-footer">
          <input type="text" className="watermark" value={watermark} onChange={watermarkHandle} />
        </div>
      </div>

    </div>
  );
}

export default App;
